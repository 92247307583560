<cft-dialog *transloco="let t" [title]="t('shared.sellingPoints.happiness.modal.title')" size="md">
  <cft-dialog-content>
    <p>{{ text }}</p>
  </cft-dialog-content>
  <cft-dialog-footer>
    <button type="button" class="btn btn-sm btn-secondary capitalize" (click)="close()">{{ t('close') }}</button>
    <a class="btn btn-sm btn-hero" [routerLink]="linkService.happinessGuaranteePagePath">
      {{ t('shared.sellingPoints.happiness.modal.more') }}
    </a>
  </cft-dialog-footer>
</cft-dialog>
